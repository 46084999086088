import Vue from "vue";
// import {
//   createApp
// } from 'vue'
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "@/store/index.js";
import "@/style/index.less";
import "swiper/css";
import "amfe-flexible/index"
import { Tabbar, TabbarItem } from 'vant'
import 'vant/lib/tabbar/style'
import 'vant/lib/tabbar-item/style'

// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview',  + to.fullPath])
    }
  }
  next()
})


Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Tabbar)
Vue.use(TabbarItem)
new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
