import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layout/MainPage.vue"),
    redirect: "/",
    children: [
      {
        path: "/",
        component: () => import("@/page/index/indexVue.vue"),
      },
      {
        path: "/home",
        component: () => import("@/page/index/homeVue.vue"),
      },
      {
        path: "/justEt",
        component: () => import("@/page/index/justEtVue.vue"),
      },
      {
        path: "/orange",
        component: () => import("@/page/index/orangeVue.vue"),
      },

      {
        path: "/dietitian",
        component: () => import("@/page/index/dietitianVue.vue"),
      },
      {
        path: "/news",
        component: () => import("@/page/index/newsVue.vue"),
      },
      {
        path: "/newsDetails",
        name: "newsDetails",
        component: () => import("@/page/index/newsDetailsVue.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
    return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
