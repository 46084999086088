import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

// state 用于存储数据
const state = {
  number: 1,
};
// 准备actions，用于响应组件中的动作
const actions = {};
// mutations 用于操作数据（state）
const mutations = {};

export default new Vuex.Store({
  actions,
  mutations,
  state,
});
